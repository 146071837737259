import React from "react";
import "../styles/global.scss";
import "../styles/button-input.scss";

export const Input = ({
  name = "",
  setValue,
  value,
  placeholder,
  type = "text",
  label,
  onChange,
  disabled = false,
  autoFocus = false,
}) => {
  const inputHandler = (e) => {
    if (typeof value === "object") {
      setValue({ ...value, [e.target.name]: e.target.value });
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <div className="input-container">
      <label className="input-label">{label}</label>
      <input
        name={name}
        className="input"
        value={name ? value[name] : value}
        onChange={onChange ? onChange : inputHandler}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </div>
  );
};
