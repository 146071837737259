import React from "react";

export const CircleHelpIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9.146 9.074a2.998 2.998 0 015.28-.838A3 3 0 0112 13v1m0 7a9 9 0 110-18 9 9 0 010 18zm.05-4v.1h-.1V17h.1z"
        ></path>
      </g>
    </svg>
  );
};
