import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/newlogo.png";
import { useAuthContext } from "../providers/provider";
import "../styles/global.scss";
import "../styles/footer.scss";
import {
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../assets/icons";

export const Footer = () => {
  const { isPhone } = useAuthContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="footer center">
      <div className="footer-container">
        <div>
          <div className={`footer-logo-con ${isPhone && "center"}`}>
            <img className="footer-logo" src={Logo} />
          </div>
          <div className="footer-text">
            Join Deriv Copy Trading, where wealth knows no limits. Be part of
            our elite group of traders and seize your destiny.
          </div>
        </div>
        <div className="footer-items">
          <div className="footer-items-list">
            <div className="footer-items-list-title">Site Map</div>
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <div className="footer-items-list-item">Home</div>
            </Link>
            {/* <div className="footer-items-list-item">Terms Of Service</div> */}
            <Link to={"/support"} style={{ textDecoration: "none" }}>
              <div className="footer-items-list-item">Support</div>
            </Link>
            <Link to={"/pricing"} style={{ textDecoration: "none" }}>
              <div className="footer-items-list-item">Pricing</div>
            </Link>
          </div>
          <div className="footer-items-list">
            <div className="footer-items-list-title">Follow us</div>
            <Link
              to={"https://t.me/deriv_ea_traders_club"}
              target="_blank"
              className="footer-items-list-link"
            >
              <div className="footer-items-list-item-icon">
                <TelegramIcon color="#eee" />
              </div>
              <div className="footer-items-list-item">Telegram</div>
            </Link>
            <Link
              to={"https://www.facebook.com/profile.php?id=61552231088743"}
              target="_blank"
              className="footer-items-list-link"
            >
              <div className="footer-items-list-item-icon">
                <FacebookIcon color="#eee" />
              </div>
              <div className="footer-items-list-item">Facebook</div>
            </Link>
            <Link
              to={"https://www.instagram.com/deriveatradersclub/"}
              target="_blank"
              className="footer-items-list-link"
            >
              <div className="footer-items-list-item-icon">
                <InstagramIcon color="#eee" />
              </div>
              <div className="footer-items-list-item">Instagram</div>
            </Link>
            <Link
              to={"https://twitter.com/EaDeriv"}
              target="_blank"
              className="footer-items-list-link"
            >
              <div className="footer-items-list-item-icon">
                <TwitterIcon color="#eee" />
              </div>
              <div className="footer-items-list-item">Twitter</div>
            </Link>
            <Link
              to={"https://www.youtube.com/channel/UC1A4wBHXejwiot29GyCazPA"}
              target="_blank"
              className="footer-items-list-link"
            >
              <div className="footer-items-list-item-icon">
                <YoutubeIcon color="#eee" />
              </div>
              <div className="footer-items-list-item">Youtube</div>
            </Link>
          </div>
          <div className="footer-items-list">
            <div className="footer-items-list-title">Contact us</div>
            <div>deatradersclub@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};
