import React from "react";

export const TrendingUpIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M20 7l-5.846 5.938c-.105.106-.158.16-.205.202-.76.68-1.907.68-2.667 0a4.814 4.814 0 01-.205-.203c-.105-.106-.158-.16-.205-.202a2 2 0 00-2.667 0c-.047.042-.1.096-.204.202L4 17M20 7v6m0-6h-6"
        ></path>
      </g>
    </svg>
  );
};
