import React, { useState } from "react";
import { Footer, Header, SearchBar } from "../components";
import { Link } from "react-router-dom";
import "../styles/global.scss";
import "../styles/support.scss";

const supportData = [
  {
    title: "Deposit & withdraw",
    body: (
      <>
        <div className="support-card-text">
          Meet our new partner, Deriv broker's official payment agent! They're
          here to make your deposits and withdrawals quick and hassle-free. Say
          hi to Wisdom Almighty and let's boost your trading game!
        </div>
        <div className="support-card-link-con">
          <Link
            to={"https://app.deriv.com/cashier/payment-agent"}
            target="_blank"
            className="support-card-link"
          >
            Deriv payment deposit & withdraw
          </Link>
        </div>
        <div className="support-card-link-con" style={{ marginTop: "8px" }}>
          <Link
            to={"https://t.me/WisdomAlmighty"}
            target="_blank"
            className="support-card-link"
          >
            Contact payment agent
          </Link>
        </div>
      </>
    ),
  },
  {
    title: "Sign up",
    body: (
      <div className="support-card-video">
        <iframe
          width="100%"
          height="200"
          src="https://www.youtube.com/embed/nkFA6udxGtc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; 
autoplay; 
clipboard-write; 
encrypted-media; 
gyroscope; 
picture-in-picture; 
web-share"
          allowFullScreen={true}
        ></iframe>
      </div>
    ),
  },
];

const SupportPage = () => {
  const [searchValue, setSeachValue] = useState("");
  const [filteredData, setFilteredData] = useState(supportData);

  return (
    <div className="container support">
      <Header />
      <div className="support-container">
        <div className="support-title center">How can we help you?</div>
        <div className="support-search-con center">
          <SearchBar
            value={searchValue}
            setValue={setSeachValue}
            data={supportData}
            setFilteredData={setFilteredData}
            placeholder="Search"
          />
        </div>
        <div className="support-contact">
          {filteredData.map((el, index) => (
            <div className="support-card-container" key={index}>
              <div className="support-card">
                <div className="support-card-title center">{el.title}</div>
                {el.body}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SupportPage;
