import React, { useEffect, useState } from "react";
import { Button, Footer, Header, Input, Loading } from "../components";
import { useAuthContext } from "../providers/provider";
import "../styles/global.scss";
import "../styles/profile.scss";

const ProfilePage = () => {
  const { user, userDerivData, loading, updateUser } = useAuthContext();
  const [updateProfile, setUpdateProfile] = useState(false);
  const [newUserData, setNewUserData] = useState({});

  useEffect(() => {
    setNewUserData(user);
    console.log(newUserData);
  }, [user]);

  const prepareSaveProfile = () => {
    setUpdateProfile(true);
  };

  const saveProfile = async () => {
    await updateUser(newUserData);
    setUpdateProfile(false);
  };

  return (
    <div className="container profile">
      <Header />
      <div className="profile-container">
        {user.email ? (
          <>
            {/* <div>
              <div>Deriv balance</div>
              <div className="profile-balance">
                {userDerivData.balance} {userDerivData.currency}
              </div>
            </div> */}
            <div style={{ fontSize: "24px" }}>Profile</div>
            <Input
              name={"firstName"}
              label={"First name"}
              value={newUserData}
              setValue={setNewUserData}
              disabled={!updateProfile}
            />
            <Input
              name={"lastName"}
              label={"Last name"}
              value={newUserData}
              setValue={setNewUserData}
              disabled={!updateProfile}
            />
            <Input
              name={"email"}
              label={"Email"}
              value={newUserData}
              setValue={setNewUserData}
              disabled={!updateProfile}
            />
            <Input
              name={"phoneNumber"}
              label={"Phone number"}
              value={newUserData}
              setValue={setNewUserData}
              disabled={!updateProfile}
            />
            {/* <Input
              name={"ctraderId"}
              label={"Ctrader id"}
              value={user}
              disabled={!updateProfile}
            />
            <Input
              name={"ctraderUsername"}
              label={"Ctrader username"}
              value={user}
              disabled={!updateProfile}
            /> */}
            {/* <Input
              name={"mt5Loginid"}
              label={"Mt5 login id"}
              value={user}
              disabled={!updateProfile}
            />
            <Input
              type="password"
              label={"Mt5 password"}
              value="********"
              disabled={!updateProfile}
            /> */}
            <Input
              type="password"
              name={"derivToken"}
              label={"Deriv token"}
              value={newUserData}
              setValue={setNewUserData}
              disabled={!updateProfile}
            />
            <Input
              name={"loginid"}
              label={"Deriv login id"}
              value={userDerivData}
              setValue={setNewUserData}
              disabled={true}
            />
            <div style={{ marginTop: "12px" }}>
              {updateProfile ? (
                <Button width="100%" onClick={saveProfile}>
                  Save
                </Button>
              ) : (
                <Button width="100%" onClick={prepareSaveProfile}>
                  Update profile
                </Button>
              )}
            </div>
          </>
        ) : (
          <div style={{ height: "300px" }}>Login first</div>
        )}
      </div>
      <Footer />
      {loading && <Loading />}
    </div>
  );
};

export default ProfilePage;
