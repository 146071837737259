import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components";
import "../styles/global.scss";
import "../styles/404.scss";

const NotFoundPage = () => {
  return (
    <div className="container center" style={{ height: "100vh" }}>
      <div className="notfound">
        <div className="notfound-title">404</div>
        <div className="notfound-description">Page not found</div>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <div className="notfound-button">
            <Button>Go to homepage</Button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
