import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Home description":
        "Copy trading is a method in which individuals automatically mimic the trading actions of experienced traders. It involves selecting a trader to follow, allocating funds to replicate their trades, and adjusting risk preferences. This can provide access to trading expertise and portfolio diversification but comes with risks and potential fees.",
      "Header home": "Home",
      "Header support": "Support",
      "Header pricing": "Pricing",
      "Header login": "Login",
      "Header signup": "Sign up",
      "Header dashboard": "Dashboard",
      "Header logout": "Log out",
      "Header profile": "Profile",
      "Dashboard current balance": "Current balance",
      "Dashboard started balance": "Started balance",
      "Dashboard monthly target": "Monthly target",
      "Dashboard weekly target": "Weekly target",
      "Dashboard no data": "No Data",
      "Loading please wait": "Please wait",
    },
  },
  mn: {
    translation: {
      "Home description":
        "Copy trading нь хувь хүмүүс туршлагатай арилжаачдын арилжааг шууд буюу автоматаар даган дуурайж арилжаа хийхийг хэлэх бөгөөд та дагаж буй тухайн арилжаачтайгаа адил эрсдлийн тооцоог гаргаж хөрөнгөө хувиарлан арилжаа хийх боломжтой юм. Ингэснээр та арилжааг нь дагаад зогсохгүй тухайн арилжаачнаас хөрөнгө хувиарлалтыг нь сурж арилжааны туршлага хуримтлуулах юм.",
      "Header home": "Нүүр хуудас",
      "Header support": "Тусламж",
      "Header pricing": "Төлбөр",
      "Header login": "Нэвтрэх",
      "Header signup": "Бүртгүүлэх",
      "Header dashboard": "Хянах самбар",
      "Header logout": "Гарах",
      "Header profile": "Миний хаяг",
      "Dashboard current balance": "Дансны үлдэгдэл",
      "Dashboard started balance": "Эхэлсэн дүн",
      "Dashboard monthly target": "Сарын зорилго",
      "Dashboard weekly target": "7 хоногийн зорилго",
      "Dashboard no data": "Өгөгдөл алга",
      "Loading please wait": "Түр хүлээнэ үү",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
