import React, { useState } from "react";
import { Button, Footer, Header, Input, Loading, Modal } from "../components";
import {
  CircleHelpIcon,
  CloseIcon,
  CopyIcon,
  RocketIcon,
} from "../assets/icons";
import BinanceQR from "../assets/images/binanceqr.png";
import { useAuthContext } from "../providers/provider";
import { Tooltip } from "react-tooltip";
import "../styles/global.scss";
import "../styles/pricing.scss";

const services = [{ icon: "", text: "Copy trading /1 month/" }];

const PricingPage = () => {
  const { user, checkPaymentNote, checkPaymentTaxID, loading, updateUser } =
    useAuthContext();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Binance pay");
  const [noteInput, setNoteInput] = useState("");
  const [taxIDInput, setTaxIDInput] = useState("");
  const [payIDCopied, setPayIDCopied] = useState(false);
  const [AddressCopied, setAddressCopied] = useState(false);
  const [mt5AccAdd, setMt5AccAdd] = useState(false);
  const [mt5Loginid, setMt5Loginid] = useState("");
  const [mt5Password, setMt5Password] = useState("");

  const paymentModalOpen = () => {
    if (user.email !== undefined) {
      // if (user.mt5Loginid !== undefined) {
      setPaymentModal(true);
      // } else {
      //   setMt5AccAdd(true);
      // }
    } else {
      alert("Sign in first");
    }
  };

  const copyText = (text, setText) => {
    navigator.clipboard.writeText(text);
    setText(true);
    setTimeout(() => {
      setText(false);
    }, 5000);
  };

  const checkPaymentNoteButton = () => {
    if (user.mt5Loginid !== undefined) {
      checkPaymentNote(noteInput);
    }
  };

  const checkPaymentTaxIDButton = () => {
    if (user.mt5Loginid !== undefined) {
      checkPaymentTaxID(taxIDInput);
    }
  };

  return (
    <div className="container">
      <Header />
      <div className="pricing">
        <div className="pricing-container">
          <div className="pricing-title center">
            Copy trading service pricing plans
          </div>
          <div className="pricing-card-container">
            <div className="pricing-card">
              <div className="pricing-card-icon">
                <RocketIcon />
              </div>
              <div className="pricing-card-title">Basic plan</div>
              <div className="pricing-card-line"></div>
              <div className="pricing-card-description">Services included</div>
              <div className="pricing-card-services">
                {services.map((el, index) => (
                  <div className="pricing-card-service" key={index}>
                    <div className="pricing-card-service-icon">{el.icon}</div>
                    <div className="pricing-card-service-text">{el.text}</div>
                  </div>
                ))}
              </div>
              <Button onClick={paymentModalOpen}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginBottom: "8px" }}>$</div>
                  <div style={{ marginLeft: "2px", fontSize: "24px" }}>50</div>
                  <div style={{ marginLeft: "8px" }}>Choose plan</div>
                </div>
              </Button>
            </div>
          </div>
        </div>
        {paymentModal && (
          <Modal>
            <div className="pricing-modal">
              <div className="pricing-modal-header">
                <div className="pricing-modal-title">Payment details</div>
                <div
                  className="pricing-modal-close"
                  onClick={() => setPaymentModal(false)}
                >
                  <CloseIcon />
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <div className="pricing-modal-choose-container">
                  <div
                    className="pricing-modal-choose center"
                    onClick={() => setPaymentMethod("Binance pay")}
                  >
                    Binance pay
                  </div>
                  <div
                    className="pricing-modal-choose center"
                    onClick={() => setPaymentMethod("Other crypto address")}
                  >
                    Other crypto address
                  </div>
                </div>
                <div
                  className="pricing-modal-choose-line"
                  style={{
                    left: paymentMethod === "Binance pay" ? 0 : "180px",
                  }}
                ></div>
              </div>
              {paymentMethod === "Binance pay" ? (
                <>
                  <div className="pricing-modal-detail-container">
                    <div className="pricing-modal-detail">
                      <div style={{ opacity: 0.5 }}>Total:</div>
                      <div>$50.00</div>
                    </div>
                  </div>
                  <div className="pricing-modal-address-field">
                    <div
                      className="pricing-modal-address-field-text"
                      style={{ opacity: 0.5 }}
                    >
                      Pay id:
                    </div>
                    <div className="pricing-modal-address-container center">
                      <div className="pricing-modal-address">317838379</div>
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={
                          payIDCopied ? "Copied" : "Copy text"
                        }
                        data-tooltip-place="top"
                      >
                        <div
                          className="pricing-modal-address-copy"
                          onClick={() => copyText("317838379", setPayIDCopied)}
                        >
                          <CopyIcon />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="pricing-modal-input">
                    <div className="pricing-modal-input-title-container">
                      <div className="pricing-modal-input-title">
                        Enter your note
                      </div>
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-place="top"
                        data-tooltip-html="Та төлбөр төлөхдөө NOTE дээр өөрийн<br/>LOGIN ID аа оруулах ба оруулсан<br/>NOTE - өө энд оруулна уу."
                      >
                        <div className="pricing-modal-input-title-icon">
                          <CircleHelpIcon />
                        </div>
                      </a>
                    </div>
                    <Input
                      placeholder="Note"
                      setValue={setNoteInput}
                      value={noteInput}
                    />
                  </div>
                  <div className="pricing-modal-button center">
                    <Button width={"100%"} onClick={checkPaymentNoteButton}>
                      Check payment
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="pricing-modal-detail-container">
                    <div className="pricing-modal-detail">
                      <div style={{ opacity: 0.5 }}>Total:</div>
                      <div>$50.00</div>
                    </div>
                    <div className="pricing-modal-detail">
                      <div style={{ opacity: 0.5 }}>Coin:</div>
                      <div style={{ textTransform: "none" }}>USDT TetherUS</div>
                    </div>
                    <div className="pricing-modal-detail">
                      <div style={{ opacity: 0.5 }}>Network:</div>
                      <div style={{ textTransform: "none" }}>
                        BNB Smart Chain (BEP20)
                      </div>
                    </div>
                  </div>
                  <div className="pricing-modal-qr-container center">
                    <img
                      className="pricing-modal-qr"
                      src={BinanceQR}
                      alt="QR"
                    />
                  </div>
                  <div className="pricing-modal-address-field">
                    <div
                      className="pricing-modal-address-field-text"
                      style={{ opacity: 0.5 }}
                    >
                      Address:
                    </div>
                    <div className="pricing-modal-address-container center">
                      <div className="pricing-modal-address">
                        0xbe172693e080de285c3824aa40cc59be31900fbf
                      </div>
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={
                          AddressCopied ? "Copied" : "Copy text"
                        }
                        data-tooltip-place="top"
                      >
                        <div
                          className="pricing-modal-address-copy"
                          onClick={() =>
                            copyText(
                              "0xbe172693e080de285c3824aa40cc59be31900fbf",
                              setAddressCopied
                            )
                          }
                        >
                          <CopyIcon />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="pricing-modal-input">
                    <div className="pricing-modal-input-title-container">
                      <div className="pricing-modal-input-title">
                        Enter your tax id
                      </div>
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-place="top"
                        data-tooltip-html="Та Binance - с бусад биржээс гүйлгээ<br/>хийж байгаа тохиолдолд гүйлгээний<br/>TAX ID - г оруулна уу."
                      >
                        <div className="pricing-modal-input-title-icon">
                          <CircleHelpIcon />
                        </div>
                      </a>
                    </div>
                    <Input
                      placeholder="TAX ID"
                      setValue={setTaxIDInput}
                      value={taxIDInput}
                    />
                  </div>
                  <div className="pricing-modal-button center">
                    <Button width={"100%"} onClick={checkPaymentTaxIDButton}>
                      Check payment
                    </Button>
                  </div>
                </>
              )}
            </div>
            <Tooltip id="my-tooltip" />
          </Modal>
        )}
        {/* {mt5AccAdd && (
          <Modal>
            <div className="pricing-modal">
              <div className="pricing-modal-header">
                <div className="pricing-modal-title">
                  First enter your mt5 account
                </div>
                <div
                  className="pricing-modal-close"
                  onClick={() => setMt5AccAdd(false)}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="pricing-modal-input">
                <Input
                  label="Mt5 login id"
                  placeholder="Login id"
                  setValue={setMt5Loginid}
                  value={mt5Loginid}
                />
              </div>
              <div className="pricing-modal-input">
                <Input
                  label="Mt5 password"
                  placeholder="Password"
                  type="password"
                  setValue={setMt5Password}
                  value={mt5Password}
                />
              </div>
              <div className="pricing-modal-button">
                <Button
                  width="100%"
                  onClick={() => updateUser(mt5Loginid, mt5Password)}
                >
                  Add mt5 account
                </Button>
              </div>
            </div>
          </Modal>
        )} */}
      </div>
      <Footer />
      {loading && <Loading />}
    </div>
  );
};

export default PricingPage;
