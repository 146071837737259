import React from "react";

export const UserIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 21a7 7 0 10-14 0m7-10a4 4 0 110-8 4 4 0 010 8z"
        ></path>
      </g>
    </svg>
  );
};
