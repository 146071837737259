import React from "react";

export const RocketIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill={color}
      version="1.1"
      viewBox="0 0 40 40"
      xmlSpace="preserve"
    >
      <path d="M21.1 27.2c-.8 0-1.3-.3-1.4-.3-.1 0-.1-.1-.2-.2 0-.1-.4-.6-.3-1.5-1-.1-1.9-.7-2.8-1.5-.9-.9-1.4-1.8-1.5-2.8-.9 0-1.5-.3-1.5-.3-.1 0-.1-.1-.2-.2s-1.4-2.3 1.7-5.4c1.3-1.3 2.7-1.5 3.8-.6.2-.2.3-.3.4-.3 2.6-2.1 4.8-2.5 6.2-2.5 1.6 0 2.5.6 2.5.6.1 0 .1.1.2.2s2.1 3.6-2 8.7c-.1.1-.2.2-.3.4.9 1.2.7 2.5-.6 3.8-1.7 1.5-3.1 1.9-4 1.9zm-.8-1.2c.1.1.4.1.8.1.7 0 1.9-.3 3.3-1.7 1.3-1.3.8-2.2.3-2.7-.3-.3-.1-.7.3-1l.2-.2s0-.1.1-.1c3.3-4.1 2.3-6.9 2-7.5-.2-.1-.9-.4-1.9-.4-1.2 0-3.2.4-5.6 2.4 0 0-.1 0-.1.1-.1 0-.2.1-.2.2-.4.3-.5.4-.7.4-.1 0-.3-.1-.4-.1-.8-.8-1.7-.7-2.7.3-2.1 2.1-1.8 3.6-1.6 4 .2.1.7.2 1.3.1.1 0 .3 0 .4.1.1.1.2.2.2.4 0 .9.4 1.8 1.3 2.6.8.8 1.7 1.3 2.6 1.3.1 0 .3.1.4.2.1.1.1.3.1.4-.3.5-.2.9-.1 1.1zM12.6 27.9c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l2.5-2.5c.2-.2.5-.2.7 0s.2.5 0 .7L13 27.7c-.1.1-.2.2-.4.2zM12 24.7c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l1.3-1.3c.2-.2.5-.2.7 0s.2.5 0 .7l-1.3 1.3c0 .1-.2.1-.3.1zM15.8 28.5c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l1.3-1.3c.2-.2.5-.2.7 0s.2.5 0 .7l-1.3 1.3c-.1.1-.2.1-.3.1z"></path>
      <path d="M22.1 20.2c-.6 0-1.2-.2-1.6-.7-.9-.9-.9-2.3 0-3.2.9-.9 2.4-.9 3.2 0 .4.4.7 1 .7 1.6s-.2 1.2-.7 1.6c-.4.4-1 .7-1.6.7zm0-3.6c-.3 0-.7.1-.9.4-.5.5-.5 1.3 0 1.8s1.3.5 1.8 0c.2-.2.4-.6.4-.9s-.1-.7-.4-.9-.5-.4-.9-.4z"></path>
    </svg>
  );
};
