import React from "react";

export const TrendingDownIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M20 17l-5.846-5.938a4.945 4.945 0 00-.205-.202 1.999 1.999 0 00-2.667 0c-.047.042-.1.096-.205.203-.105.106-.158.16-.205.202a2 2 0 01-2.667 0c-.047-.042-.1-.096-.205-.203L4 7m16 10v-6m0 6h-6"
        ></path>
      </g>
    </svg>
  );
};
