import React from "react";
import { useAuthContext } from "../providers/provider";
import { Button, Input, Loading } from "../components";
import Logo from "../assets/images/logo-global.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../styles/global.scss";
import "../styles/login.scss";

let userSchema = Yup.object().shape({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  const { login, loading } = useAuthContext();
  const { values, errors, touched, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: userSchema,
      onSubmit: (values, { setSubmitting }) => {
        login(values, setSubmitting);
      },
    });

  return (
    <div className="container center">
      <form className="login">
        <div className="login-logo-con center">
          <img className="login-logo" src={Logo} />
        </div>
        <div className="login-input">
          <Input
            name="email"
            label="Enter your email"
            type="email"
            placeholder="EMAIL"
            onChange={handleChange}
            value={values.email}
            autoFocus
          />
          {errors.email && touched.email && (
            <p className="error">{errors.email}</p>
          )}
        </div>
        <div className="login-input">
          <Input
            name="password"
            label="Enter your password"
            type="password"
            placeholder="PASSWORD"
            onChange={handleChange}
            value={values.password}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          {errors.password && touched.password && (
            <p className="error">{errors.password}</p>
          )}

          <div className="login-forgotpassword">
            <Link to={"/forgot-password"} style={{ color: "#ff2e63" }}>
              Forgot password
            </Link>
          </div>
        </div>
        <div className="login-button">
          <Button
            type="submit"
            width={"100%"}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Login
          </Button>
        </div>
        <div className="login-already center">
          <div>Don't have an account?</div>
          <Link to={"/signup"} className="login-already-href">
            Sign Up
          </Link>
        </div>
      </form>
      {loading && (
        <div className="loading center">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default LoginPage;
