import React, { useEffect, useState } from "react";
import { Footer, Header } from "../components";
import DerivAPI from "@deriv/deriv-api/dist/DerivAPI.js";
import "../styles/global.scss";
import "../styles/trader.scss";

const TOKEN_OF_TRADER = process.env.REACT_APP_DERIV_TOKEN;
const DEMO_TOKEN_OF_TRADER = process.env.REACT_APP_DERIV_TOKEN_DEMO;
const APP_ID = process.env.REACT_APP_APP_ID;

const connection = new WebSocket(
  `wss://ws.binaryws.com/websockets/v3?app_id=${APP_ID}`
);

const api = new DerivAPI({ connection });

const TraderPage = () => {
  const [realCopiers, setRealCopiers] = useState([]);
  const [virtualCopiers, setVirtualCopiers] = useState([]);

  useEffect(() => {
    api.basic.ping();

    const fetchCopyTraderData = async () => {
      try {
        const authorizeRes = await api.basic.authorize({
          authorize: TOKEN_OF_TRADER,
        });

        try {
          const copyTradersList = await api.basic.copytradingList({
            copytrading_list: 1,
          });

          setRealCopiers(copyTradersList.copytrading_list.copiers);
        } catch (authErr) {
          console.log(authErr);
        }
      } catch (authErr) {
        console.log(authErr);
      }
    };

    fetchCopyTraderData();
  }, []);

  useEffect(() => {
    if (realCopiers.length !== 0) {
      const fetchVirtualCopyTraderData = async () => {
        api.basic.logout({ logout: 1 });

        try {
          const authorizeRes = await api.basic.authorize({
            authorize: DEMO_TOKEN_OF_TRADER,
          });

          try {
            const copyTradersList = await api.basic.copytradingList({
              copytrading_list: 1,
            });

            setVirtualCopiers(copyTradersList.copytrading_list.copiers);
          } catch (authErr) {
            console.log(authErr);
          }
        } catch (authErr) {
          console.log(authErr);
        }
      };

      fetchVirtualCopyTraderData();
    }
  }, [realCopiers]);

  return (
    <div className="trader container">
      <Header />
      <div className="trader-container">
        <div>
          <div className="trader-title">Real copiers {realCopiers.length}</div>
          {realCopiers.map((el) => (
            <div className="trader-copier">
              <div className="trader-copier-key">Login id:</div>
              <div className="trader-copier-value">{el.loginid}</div>
            </div>
          ))}
        </div>
        <div>
          <div className="trader-title">
            Virtual copiers {virtualCopiers.length}
          </div>
          {virtualCopiers.map((el) => (
            <div className="trader-copier">
              <div className="trader-copier-key">Login id:</div>
              <div className="trader-copier-value">{el.loginid}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TraderPage;
