import React, { createContext, useState, useEffect, useContext } from "react";
import DerivAPI from "@deriv/deriv-api/dist/DerivAPI.js";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export const DerivBotContext = createContext({
  user: {},
  userDerivData: {},
  login: () => {},
  signUp: () => {},
  logOut: () => {},
  loading: Boolean,
  setLoading: () => {},
  isPhone: Boolean,
  language: "",
  changeLanguage: () => {},
  checkPaymentNote: () => {},
  checkPaymentTaxID: () => {},
  sendResetPasswordEmail: () => {},
  resetPassword: () => {},
  updateUser: () => {},
  // updateAllUsers: () => {},
  // sendLinksToEmails: () => {},
});

const APP_ID = process.env.REACT_APP_APP_ID;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const connection = new WebSocket(
  `wss://ws.binaryws.com/websockets/v3?app_id=${APP_ID}`
);

const api = new DerivAPI({ connection });

// const emailsAndLinks = [];

export const Provider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userDerivData, setUserDerivData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "en"
  );
  const tokenLocal = localStorage.getItem("token");
  const { i18n } = useTranslation();

  const successToast = (message) =>
    toast.success(message, {
      // Амжилттай нэвтэрлээ.
      // Амжилттай бүртгэгдлээ.
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const errorToast = (error) =>
    toast.error(error, {
      // буруу байна.
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const LogOutToast = () =>
    toast.info("Successfully logout.", {
      // Амжилттай гарлаа.
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  useEffect(() => {
    window.innerWidth < 481 ? setIsPhone(true) : setIsPhone(false);

    if (localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"));
    } else {
      localStorage.setItem("language", "en");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("language"))
      i18n.changeLanguage(localStorage.getItem("language"));
  }, [i18n, language]);

  useEffect(() => {
    const fetchData = async () => {
      if (tokenLocal) {
        setLoading(true);

        try {
          const res = await axios.get(`${BACKEND_URL}/user/${tokenLocal}`, {
            headers: {
              Authorization: `Bearer ${tokenLocal}`,
            },
          });
          setUser(res.data.data);
        } catch (err) {
          localStorage.removeItem("token");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [tokenLocal]);

  useEffect(() => {
    api.basic.ping();

    const fetchDerivData = async () => {
      if (user.derivToken) {
        try {
          const response = await api.basic.authorize({
            authorize: user?.derivToken,
          });

          setUserDerivData(response.authorize);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchDerivData();
  }, [user.derivToken]);

  const login = async (values, setSubmitting) => {
    setLoading(true);
    values.email = values.email.toLowerCase();
    try {
      const response = await axios.post(`${BACKEND_URL}/user/login`, values);
      successToast("Successfully logged in");
      localStorage.setItem("token", response.data.token);
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
    } catch (err) {
      errorToast(err.response.data.message);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const signUp = async (userData, setSubmitting) => {
    setLoading(true);
    api.basic.ping();
    userData.email = userData.email.toLowerCase();
    const { confirmPassword, ...details } = userData;

    try {
      const derivRes = await api.basic.authorize({
        authorize: details.derivToken,
      });

      details.startedBalance = derivRes.authorize.balance;
      details.derivLoginid = derivRes.authorize.loginid;

      const backendRes = await axios.post(
        `${BACKEND_URL}/user/createUser`,
        details
      );
      if (backendRes.data !== "Already logged in") {
        localStorage.setItem("token", backendRes.data.token);
        successToast("Successfully signed up");
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      } else {
        errorToast("This email is already used in other account."); // Бүртгэлтэй email байна.
      }
    } catch (backendErr) {
      console.log(backendErr);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
    // } catch (derivErr) {
    //   errorToast(derivErr.error.message);
    // }
  };

  const logOut = async () => {
    localStorage.removeItem("token");
    await api.basic.logout({ logout: 1 });
    LogOutToast();
    setTimeout(() => {
      window.location.href = "/";
      window.location.pathname === "/" && window.location.reload();
    }, 1000);
  };

  const changeLanguage = (lng) => {
    setLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const sendResetPasswordEmail = async (email, setSubmitting) => {
    try {
      const res = await axios.post(`${BACKEND_URL}/user/forgotPassword`, {
        email: email.verifyEmail,
      });

      successToast(res.data.message);
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const resetPassword = async (values, setSubmitting) => {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/user/resetPassword/${values.id}/${values.token}`,
        { password: values.newPassword }
      );

      if (res.data.success) {
        successToast(res.data.message);
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const checkPaymentNote = async (note) => {
    try {
      const paymentRes = await axios.post(
        `${BACKEND_URL}/payment/note`,
        {
          note,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenLocal}`,
          },
        }
      );

      console.log(paymentRes);
    } catch (err) {
      console.log(err);
    }
  };

  const checkPaymentTaxID = async (taxid) => {
    try {
      const paymentRes = await axios.post(
        `${BACKEND_URL}/payment/taxid`,
        {
          taxid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenLocal}`,
          },
        }
      );

      console.log(paymentRes);
    } catch (err) {
      console.log(err);
    }
  };

  const updateUser = async (newUserData) => {
    try {
      const res = await axios.put(
        `${BACKEND_URL}/user/updateUser/${user._id}`,
        newUserData
      );

      if (res.data.success) {
        successToast("Account successfully updated");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const sendLinksToEmails = () => {
  //   emailsAndLinks.map(async (el) => {
  //     try {
  //       const res = await axios.post(
  //         `${BACKEND_URL}/user/sendInvitationLinksToEmails`,
  //         { email: el.email, link: el.link }
  //       );

  //       console.log(res);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   });
  // };

  return (
    <DerivBotContext.Provider
      value={{
        login,
        signUp,
        logOut,
        user,
        userDerivData,
        loading,
        setLoading,
        isPhone,
        language,
        changeLanguage,
        checkPaymentNote,
        checkPaymentTaxID,
        sendResetPasswordEmail,
        resetPassword,
        updateUser,
      }}
    >
      {children}
    </DerivBotContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(DerivBotContext);
};
