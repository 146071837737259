import React, { useEffect, useState } from "react";
import DerivAPI from "@deriv/deriv-api/dist/DerivAPI.js";
import { useAuthContext } from "../providers/provider";
import {
  Button,
  Card,
  Footer,
  Header,
  Input,
  Loading,
  Modal,
  Table,
  // MyChart,
} from "../components";
import {
  CalendarIcon,
  UserIcon,
  MailIcon,
  TrendingDownIcon,
  TrendingUpIcon,
} from "../assets/icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import "../styles/global.scss";
import "../styles/dashboard.scss";

const TOKEN_OF_TRADER = process.env.REACT_APP_DERIV_TOKEN;
const DEMO_TOKEN_OF_TRADER = process.env.REACT_APP_DERIV_TOKEN_DEMO;
const APP_ID = process.env.REACT_APP_APP_ID;

const connection = new WebSocket(
  `wss://ws.binaryws.com/websockets/v3?app_id=${APP_ID}`
);

const api = new DerivAPI({ connection });

const DashboardPage = () => {
  const { user, loading, userDerivData, updateUser, setLoading } =
    useAuthContext();
  // const [disableButton, setDisableButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [profitData, setProfitData] = useState([]);
  const [sumOfProfit, setSumOfProfit] = useState(0);
  const [userDerivToken, setUserDerivToken] = useState("");
  const { t } = useTranslation();
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("synthetics", {
      header: "Synthetic index",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("purchase_time", {
      header: "Purchase time",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("sell_time", {
      header: "Sell time",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("profit", {
      header: "Profit",
      cell: (info) => (
        <div className="table-profit">
          <div className="table-profit-icon">
            {info.getValue() < 0 ? (
              <TrendingDownIcon color="#ff0000" />
            ) : (
              <TrendingUpIcon color="#3aae2a" />
            )}
          </div>
          <div
            style={{
              color: info.getValue() < 0 ? "#ff0000" : "#3aae2a",
            }}
          >
            {info.getValue() >= 0 ? "+" + info.getValue() : info.getValue()}
          </div>
        </div>
      ),
    }),
  ];

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      if (user.derivToken) {
        try {
          await api.basic.authorize({
            authorize: user.derivToken,
          });
        } catch (err) {
          console.log(err.error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      if (user.derivToken && !user.derivLoginid) {
        const res = await api.basic.authorize({
          authorize: user.derivToken,
        });

        if (user.derivLoginid === undefined) {
          try {
            await updateUser({ derivLoginid: res.authorize.loginid });
          } catch (err) {
            console.log(err);
          }
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();

    //   const fetchCopyTraderData = async () => {
    //     try {
    //       const copyTradersList = await api.basic.copytradingList({
    //         copytrading_list: 1,
    //       });

    //       if (
    //         copyTradersList.copytrading_list.traders[0]?.token !== TOKEN_OF_TRADER
    //       ) {
    //         setDisableButton(false);
    //       }
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   };
  }, [user]);

  useEffect(() => {
    const fetchProfitData = async () => {
      let dateObj = new Date();
      let year = dateObj.getUTCFullYear();
      let month = dateObj.getUTCMonth() + 1;
      let day = dateObj.getUTCDate();
      try {
        const res = await api.basic.profitTable({
          profit_table: 1,
          description: 1,
          date_from: `${year}-${month}-${day}`,
          sort: "DESC",
        });

        let arr = [];
        let totalProfit = 0;
        res.profit_table.transactions.forEach((el) => {
          totalProfit += parseFloat((el.sell_price - el.buy_price).toFixed(2));
          let object = {
            synthetics: el.longcode
              .replace("Win payout if ", "")
              .split(" is ")[0],
            profit: (el.sell_price - el.buy_price).toFixed(2),
            purchase_time: moment(el.purchase_time * 1000).format("lll"),
            sell_time: moment(el.sell_time * 1000).format("lll"),
          };
          arr.push(object);
        });
        setSumOfProfit(totalProfit);
        setProfitData(arr);
      } catch (err) {
        console.log(err);
      }
    };

    if (userDerivData.loginid) {
      setTimeout(() => {
        fetchProfitData();
      }, 500);
    }
  }, [userDerivData]);

  const startCopyTrader = async () => {
    await api.basic.authorize({
      authorize: user.derivToken,
    });
    if (userDerivData.is_virtual === 0) {
      await api.basic.copyStart({ copy_start: TOKEN_OF_TRADER }).then((res) => {
        setShowModal(true);
      });
    } else if (userDerivData.is_virtual === 1) {
      await api.basic
        .copyStart({ copy_start: DEMO_TOKEN_OF_TRADER })
        .then((res) => {
          setShowModal(true);
        });
    }
  };

  const stopCopyTrader = async () => {
    await api.basic.copyStop({ copy_stop: TOKEN_OF_TRADER }).then((res) => {
      console.log(res);
    });
  };

  return (
    <div className="container">
      <div className="dashboard">
        <Header />
        {user.email ? (
          <div className="dashboard-container">
            <Card>
              <div className="dashboard-user-info-con">
                <div className="dashboard-user-info">
                  <MailIcon />
                  <div className="dashboard-user-info-text">
                    {user ? user.email : "user not authorized"}
                  </div>
                </div>
                <div className="dashboard-user-info">
                  <div style={{ height: "24px" }}>
                    <UserIcon />
                  </div>
                  <div className="dashboard-user-info-text">
                    {user.derivLoginid ? (
                      user.derivLoginid
                    ) : (
                      <div style={{ opacity: 0.5 }}>
                        Deriv token isn't added
                      </div>
                    )}
                  </div>
                </div>
                <div className="dashboard-user-info">
                  <CalendarIcon />
                  <div className="dashboard-user-info-text">
                    {user.startedDate ? (
                      moment(user.startedDate).format("l")
                    ) : (
                      <div style={{ opacity: 0.5 }}>Paid only</div>
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <Card
              flip
              front={
                <div className="dashboard-balance">
                  <div className="dashboard-chart-circle">
                    <CircularProgressbar
                      value={
                        userDerivData.balance
                          ? userDerivData.balance -
                            parseFloat(user.startedBalance?.$numberDecimal)
                          : 0
                      }
                      maxValue={300}
                      text={`${(
                        (userDerivData.balance
                          ? userDerivData.balance -
                            parseFloat(user.startedBalance?.$numberDecimal)
                          : 0) / 3
                      ).toPrecision(3)}%`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "round", // 'butt' or 'round'
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: "#3aae2a",
                        textColor: "#eeeeee",
                        trailColor: "#fff",
                        backgroundColor: "#000",
                      })}
                    />
                  </div>
                  <div className="dashboard-balance-info">
                    <div>
                      <div className="dashboard-balance-title">
                        {t("Dashboard current balance")}
                      </div>
                      <div className="dashboard-balance-text">******</div>
                    </div>
                    <div>
                      <div className="dashboard-balance-title">
                        {t("Dashboard started balance")}
                      </div>
                      <div className="dashboard-balance-text-med">******</div>
                      <div className="dashboard-balance-title">
                        {t("Dashboard monthly target")}
                      </div>
                      <div className="dashboard-balance-text-med">******</div>
                    </div>
                  </div>
                </div>
              }
              back={
                <div className="dashboard-balance">
                  <div className="dashboard-chart-circle">
                    <CircularProgressbar
                      value={
                        userDerivData.balance
                          ? userDerivData.balance -
                            parseFloat(user.startedBalance?.$numberDecimal)
                          : 0
                      }
                      maxValue={300}
                      text={`${(
                        (userDerivData.balance
                          ? userDerivData.balance -
                            parseFloat(user.startedBalance?.$numberDecimal)
                          : 0) / 3
                      ).toPrecision(3)}%`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "round", // 'butt' or 'round'
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: "#3aae2a",
                        textColor: "#eeeeee",
                        trailColor: "#fff",
                        backgroundColor: "#000",
                      })}
                    />
                  </div>
                  <div className="dashboard-balance-info">
                    <div>
                      <div className="dashboard-balance-title">
                        {t("Dashboard current balance")}
                      </div>
                      <div className="dashboard-balance-text">
                        {userDerivData
                          ? `${userDerivData.balance} ${userDerivData.currency}`
                          : "user not authorized"}
                      </div>
                    </div>
                    <div>
                      <div className="dashboard-balance-title">
                        {t("Dashboard started balance")}
                      </div>
                      <div className="dashboard-balance-text-med">
                        {user
                          ? `${parseFloat(
                              user.startedBalance?.$numberDecimal
                            )} USD`
                          : 0}
                      </div>
                      <div className="dashboard-balance-title">
                        {t("Dashboard monthly target")}
                      </div>
                      <div className="dashboard-balance-text-med">
                        {user
                          ? `${
                              parseFloat(user.startedBalance?.$numberDecimal) +
                              300
                            } USD`
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <Card>
              <div className="dashboard-copytrade">Copy trading status</div>
              <Button onClick={startCopyTrader} disabled={true}>
                {/* {disableButton ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Activated<div className="dot dot-green"></div>
                  </div>
                ) : ( */}
                Start Copy
                {/* )} */}
              </Button>
            </Card>
            <Card>
              <div className="dashboard-table-date-container">
                <div className="dashboard-table-date">
                  Today: {moment().format("LL")}
                </div>
                <div className="dashboard-table-total">
                  Total:{" "}
                  <span
                    style={{ color: sumOfProfit < 0 ? "#ff0000" : "#3aae2a" }}
                  >
                    {sumOfProfit.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="dashboard-table">
                <Table columns={columns} data={profitData} />
              </div>
            </Card>
            {/* <MyChart /> */}
          </div>
        ) : (
          <div style={{ height: "300px", margin: "32px" }}>Login first</div>
        )}
        <Footer />
      </div>
      {user.email && user.derivToken === undefined ? (
        <Modal>
          <div className="dashboard-modal">
            <div className="dashboard-modal-text">Enter your deriv token.</div>
            <Input
              placeholder="DERIV TOKEN"
              setValue={setUserDerivToken}
              value={userDerivToken}
            />
            <Button
              width="280px"
              onClick={() => updateUser({ derivToken: userDerivToken })}
              disabled={userDerivToken === "" ? true : false}
            >
              Ok
            </Button>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {showModal && (
        <Modal>
          <div className="dashboard-modal">
            <div className="dashboard-modal-text">
              Successfully copied trader.
            </div>
            <Button width={"100%"} onClick={() => setShowModal(false)}>
              Ok
            </Button>
          </div>
        </Modal>
      )}
      {loading && <Loading />}
    </div>
  );
  // return (
  //   <div className="container">
  //     <div
  //       style={{
  //         position: "fixed",
  //         width: "100%",
  //         backgroundColor: "#28282b",
  //         top: 0,
  //       }}
  //     >
  //       <Header />
  //     </div>
  //     {user.hasPaid ? (
  //       <div style={{ height: "90vh" }}>
  //         <iframe
  //           title="ctrader"
  //           src={process.env.REACT_APP_CTRADER_APP}
  //           frameBorder="none"
  //           style={{ width: "100%", height: "100%", marginTop: "80px" }}
  //         ></iframe>
  //       </div>
  //     ) : (
  //       <div className="center" style={{ fontSize: "24px", height: "80vh" }}>
  //         Paid users only
  //       </div>
  //     )}
  //     <div style={{ marginTop: "80px" }}>
  //       <Footer />
  //     </div>
  //   </div>
  // );
};

export default DashboardPage;
