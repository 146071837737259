import React from "react";

export const TwitterIcon = ({ color = "#000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      fill={color}
    >
      <path d="M21.543 7.104c.014.211.014.423.014.636 0 6.507-4.954 14.01-14.01 14.01v-.004A13.936 13.936 0 010 19.539a9.881 9.881 0 007.287-2.041 4.93 4.93 0 01-4.6-3.42 4.926 4.926 0 002.223-.084A4.924 4.924 0 01.96 9.167v-.062a4.88 4.88 0 002.235.616A4.927 4.927 0 011.67 3.148a13.982 13.982 0 0010.148 5.145 4.929 4.929 0 018.391-4.491 9.873 9.873 0 003.127-1.195 4.947 4.947 0 01-2.165 2.724A9.89 9.89 0 0024 4.555a10.008 10.008 0 01-2.457 2.549z"></path>
    </svg>
  );
};
