import React from "react";
import { Button, Input, Loading } from "../components";
import { useAuthContext } from "../providers/provider";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../assets/images/logo-global.png";
import "../styles/global.scss";
import "../styles/login.scss";

let emailSchema = Yup.object().shape({
  verifyEmail: Yup.string()
    .email("Email is invalid")
    .required("Email is required"),
});

const ForgotPasswordPage = () => {
  const { sendResetPasswordEmail, loading } = useAuthContext();
  const { values, errors, touched, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: {
        verifyEmail: "",
      },
      validationSchema: emailSchema,
      onSubmit: (values, { setSubmitting }) => {
        sendResetPasswordEmail(values, setSubmitting);
      },
    });

  return (
    <div className="container center">
      <form className="forgot-password">
        <div className="login-logo-con center">
          <img className="login-logo" src={Logo} />
        </div>
        <div className="forgot-password-title">Forgot password?</div>
        <div>
          Enter the email address associated with your account and we'll send
          you a link to reset password.
        </div>
        <Input
          name="verifyEmail"
          placeholder="Email"
          value={values}
          onChange={handleChange}
          autoFocus
        />
        {errors.verifyEmail && touched.verifyEmail && (
          <p className="error">{errors.verifyEmail}</p>
        )}
        <Button width="100%" onClick={handleSubmit} disabled={isSubmitting}>
          Send
        </Button>
      </form>
      {loading && <Loading />}
    </div>
  );
};

export default ForgotPasswordPage;
