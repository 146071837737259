import React from "react";
import "../styles/global.scss";
import "../styles/modal.scss";

export const Modal = ({ children, show }) => {
  return (
    <div className="modal-container center">
      <div className="modal-item">{children}</div>
    </div>
  );
};
