import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, DropDown } from "./";
import {
  ChartLineIcon,
  CloseIcon,
  LogoutIcon,
  MenuIcon,
  UserCircleIcon,
  UserIcon,
} from "../assets/icons";
import { useAuthContext } from "../providers/provider";
import Logo from "../assets/images/newlogo.png";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import "../styles/global.scss";
import "../styles/header.scss";

export const Header = () => {
  const { user, logOut, isPhone, language, changeLanguage } = useAuthContext();
  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="header center">
      <div className="header-container">
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {isPhone && (
            <DropDown
              list={["en", "mn"]}
              value={language}
              setValue={changeLanguage}
            />
          )}
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <div className="header-logo-con center">
              <img className="header-logo" src={Logo} />
            </div>
          </Link>
        </div>
        {isPhone ? (
          <div className="header-menu-icon" onClick={() => setMenu(true)}>
            <MenuIcon />
          </div>
        ) : (
          <div className="header-items">
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <div className="header-item">{t("Header home")}</div>
            </Link>
            <Link to={"/support"} style={{ textDecoration: "none" }}>
              <div className="header-item">{t("Header support")}</div>
            </Link>
            <Link to={"/faq"} style={{ textDecoration: "none" }}>
              <div className="header-item">FAQ</div>
            </Link>
            <Link to={"/pricing"} style={{ textDecoration: "none" }}>
              <div className="header-item">{t("Header pricing")}</div>
            </Link>
            {/* <Link to={"/terms-of-service"} style={{ textDecoration: "none" }}>
              <div className="header-item">Terms Of Service</div>
            </Link> */}
            {user.email !== undefined ? (
              <a data-tooltip-id="header-profile" data-tooltip-place="top-end">
                <div
                  className="header-profile-icon"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <UserCircleIcon />
                </div>
              </a>
            ) : (
              <>
                <Link to={"/login"} style={{ textDecoration: "none" }}>
                  <div className="header-item">{t("Header login")}</div>
                </Link>
                <Link to={"/signup"} style={{ textDecoration: "none" }}>
                  <Button>{t("Header signup")}</Button>
                </Link>
              </>
            )}
            <DropDown
              list={["en", "mn"]}
              value={language}
              setValue={changeLanguage}
            />
            <Tooltip
              id="header-profile"
              clickable
              isOpen={dropdown}
              style={{ zIndex: 5, padding: 0 }}
            >
              <div className="header-profile">
                <Link
                  to={"/dashboard"}
                  className="header-profile-dropdown-container"
                  style={{ textDecoration: "none" }}
                >
                  <div className="header-profile-dropdown-icon">
                    <ChartLineIcon />
                  </div>
                  <div className="header-profile-dropdown-text">
                    {t("Header dashboard")}
                  </div>
                </Link>
                <Link
                  to={"/profile"}
                  className="header-profile-dropdown-container"
                  style={{ textDecoration: "none" }}
                >
                  <div className="header-profile-dropdown-icon">
                    <UserIcon />
                  </div>
                  <div className="header-profile-dropdown-text">
                    {t("Header profile")}
                  </div>
                </Link>
                <div className="header-profile-dropdown-line"></div>
                <div
                  className="header-profile-dropdown-container"
                  onClick={logOut}
                >
                  <div className="header-profile-dropdown-icon">
                    <LogoutIcon />
                  </div>
                  <div className="header-profile-dropdown-text">
                    {t("Header logout")}
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <div
        className="header-menu-background"
        style={
          menu
            ? { opacity: 1, visibility: "visible" }
            : { opacity: 0, visibility: "hidden" }
        }
      ></div>
      <div className="header-menu" style={{ left: menu ? 0 : "-300px" }}>
        <div
          className="header-menu-item"
          style={{ justifyContent: "flex-end" }}
        >
          <div className="header-menu-close" onClick={() => setMenu(false)}>
            <CloseIcon />
          </div>
        </div>
        <div className="header-menu-item">
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <div className="header-item">{t("Header home")}</div>
          </Link>
        </div>
        <div className="header-menu-item">
          <Link to={"/support"} style={{ textDecoration: "none" }}>
            <div className="header-item">{t("Header support")}</div>
          </Link>
        </div>
        <div className="header-menu-item">
          <Link to={"/faq"} style={{ textDecoration: "none" }}>
            <div className="header-item">FAQ</div>
          </Link>
        </div>
        <div className="header-menu-item">
          <Link to={"/pricing"} style={{ textDecoration: "none" }}>
            <div className="header-item">{t("Header pricing")}</div>
          </Link>
        </div>
        {user.email !== undefined ? (
          <>
            <div className="header-menu-item">
              <Link to={"/dashboard"} style={{ textDecoration: "none" }}>
                <div className="header-item">{t("Header dashboard")}</div>
              </Link>
            </div>
            <div className="header-menu-item">
              <Link to={"/profile"} style={{ textDecoration: "none" }}>
                <div className="header-item">{t("Header profile")}</div>
              </Link>
            </div>
            {/* <div className="header-menu-item">
              <Link to={"/terms-of-service"} style={{ textDecoration: "none" }}>
                <div className="header-item">Terms Of Service</div>
              </Link>
            </div> */}
            <Button onClick={logOut} width={"100%"}>
              {t("Header logout")}
            </Button>
          </>
        ) : (
          <>
            {/* <div className="header-menu-item">
              <Link to={"/terms-of-service"} style={{ textDecoration: "none" }}>
                <div className="header-item">Terms Of Service</div>
              </Link>
            </div> */}
            <div className="header-menu-item">
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                <div className="header-item">{t("Header login")}</div>
              </Link>
            </div>
            <Link to={"/signup"} style={{ textDecoration: "none" }}>
              <Button width={"100%"}>{t("Header signup")}</Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
