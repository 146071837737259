import React from "react";
import { SearchIcon } from "../assets/icons";
import "../styles/global.scss";
import "../styles/searchbar.scss";

export const SearchBar = ({
  setValue,
  value,
  placeholder = "text",
  data,
  setFilteredData,
}) => {
  const searchInputHandler = (e) => {
    const searchTerm = e.target.value;
    setValue(searchTerm);

    const filteredItems = data.filter((el) =>
      el.title.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    setFilteredData(filteredItems);
  };

  return (
    <div className="searchbar">
      <input
        className="searchbar-input"
        value={value}
        onChange={searchInputHandler}
        placeholder={placeholder}
      />
      <div className="searchbar-icon-con">
        <SearchIcon color="#ff2e63" />
      </div>
    </div>
  );
};
