import React from "react";
import { Button, Input, Loading } from "../components";
import { useAuthContext } from "../providers/provider";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../assets/images/logo-global.png";
import { useParams } from "react-router-dom";
import "../styles/global.scss";
import "../styles/login.scss";

let passwordSchema = Yup.object().shape({
  newPassword: Yup.string().required("Password is required"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const ResetPasswordPage = () => {
  const { id, token } = useParams();
  const { resetPassword, loading } = useAuthContext();
  const { values, errors, touched, handleChange, isSubmitting, handleSubmit } =
    useFormik({
      initialValues: {
        newPassword: "",
        confirmNewPassword: "",
        id,
        token,
      },
      validationSchema: passwordSchema,
      onSubmit: (values, { setSubmitting }) => {
        resetPassword(values, setSubmitting);
      },
    });

  return (
    <div className="container center">
      <form className="forgot-password">
        <div className="login-logo-con center">
          <img className="login-logo" src={Logo} />
        </div>
        <div className="forgot-password-title">Reset password</div>
        <Input
          name="newPassword"
          label="Enter your new password"
          type="password"
          placeholder="New password"
          onChange={handleChange}
          value={values}
          autoFocus
        />
        {errors.newPassword && touched.newPassword && (
          <p className="error">{errors.newPassword}</p>
        )}
        <Input
          name="confirmNewPassword"
          label="Confirm your new password"
          type="password"
          placeholder="Confirm password"
          onChange={handleChange}
          value={values}
        />
        {errors.confirmNewPassword && touched.confirmNewPassword && (
          <p className="error">{errors.confirmNewPassword}</p>
        )}
        <Button width="100%" onClick={handleSubmit} disabled={isSubmitting}>
          Reset password
        </Button>
      </form>
      {loading && <Loading />}
    </div>
  );
};

export default ResetPasswordPage;
