import React, { useState } from "react";
import { useAuthContext } from "../providers/provider";
import { Button, Input, Loading } from "../components";
import Logo from "../assets/images/logo-global.png";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../styles/global.scss";
import "../styles/login.scss";

let userSchema = Yup.object().shape({
  firstName: Yup.string().min(2).max(60).required("First name is required"),
  lastName: Yup.string().min(2).max(60).required("Last name is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  phoneNumber: Yup.number().required("Phone number is required"),
  derivToken: Yup.string().required("Ctrader id is required"),
  password: Yup.string().min(8).max(32).required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const SignUpPage = () => {
  const { signUp, loading } = useAuthContext();
  const [checked, setChecked] = useState(false);
  const {
    values,
    errors,
    touched,
    handleChange,
    isSubmitting,
    handleSubmit,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      derivToken: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: userSchema,
    onSubmit: (values, { setSubmitting }) => {
      signUp(values, setSubmitting);
    },
  });

  // useEffect(() => {
  //   if (localStorage.getItem("token") !== null) {
  //     window.location.href = "/dashboard";
  //   }
  // }, []);

  return (
    <div className="container center">
      <form className="login">
        <div className="login-logo-con center">
          <img className="login-logo" src={Logo} />
        </div>
        <div className="login-title">Sign up</div>
        <div className="login-input">
          <Input
            name="firstName"
            placeholder="FIRST NAME"
            onChange={handleChange}
            value={values.firstName}
          />
          {errors.firstName && touched.firstName && (
            <p className="error">{errors.firstName}</p>
          )}
        </div>
        <div className="login-input">
          <Input
            name="lastName"
            placeholder="LAST NAME"
            onChange={handleChange}
            value={values.lastName}
          />
          {errors.lastName && touched.lastName && (
            <p className="error">{errors.lastName}</p>
          )}
        </div>
        <div className="login-input">
          <Input
            name="email"
            type="email"
            placeholder="EMAIL"
            onChange={handleChange}
            value={values.email}
          />
          {errors.email && touched.email && (
            <p className="error">{errors.email}</p>
          )}
        </div>
        <div className="login-input">
          <Input
            name="phoneNumber"
            type="number"
            placeholder="PHONE NUMBER"
            onChange={handleChange}
            value={values.phoneNumber}
          />
          {errors.phoneNumber && touched.phoneNumber && (
            <p className="error">{errors.phoneNumber}</p>
          )}
        </div>
        <div className="login-input">
          <Input
            name="derivToken"
            type="password"
            placeholder="DERIV TOKEN"
            onChange={handleChange}
            value={values.derivToken}
          />
          {errors.derivToken && touched.derivToken && (
            <p className="error">{errors.derivToken}</p>
          )}
        </div>
        <div className="login-input">
          <Input
            name="password"
            type="password"
            placeholder="PASSWORD"
            onChange={handleChange}
            value={values.password}
          />
          {errors.password && touched.password && (
            <p className="error">{errors.password}</p>
          )}
        </div>
        <div className="login-input">
          <Input
            name="confirmPassword"
            type="password"
            placeholder="CONFIRM PASSWORD"
            onChange={handleChange}
            value={values.confirmPassword}
          />
          {errors.confirmPassword && touched.confirmPassword && (
            <p className="error">{errors.confirmPassword}</p>
          )}
        </div>
        <div className="login-agree">
          <input
            style={{ backgroundColor: checked && "#ff2e63" }}
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <div>Yes, I agree to the</div>
          <Link
            to={"/terms-of-service"}
            target="_blank"
            style={{ color: "#ff2e63", marginLeft: "6px" }}
          >
            Terms of Service
          </Link>
        </div>
        <div className="login-button">
          <Button
            type="submit"
            width={"100%"}
            onClick={handleSubmit}
            disabled={isSubmitting || !checked || !isValid || !dirty}
          >
            Sign Up
          </Button>
        </div>
        <div className="login-already center">
          <div>Already created an account?</div>
          <Link to={"/login"} className="login-already-href">
            Login
          </Link>
        </div>
      </form>
      {loading && (
        <div className="loading center">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
