import React from "react";

export const SearchIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 15l6 6m-11-4a7 7 0 110-14 7 7 0 010 14z"
        ></path>
      </g>
    </svg>
  );
};
