import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "./providers/provider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import SignUpPage from "./pages/Signup";
import DashboardPage from "./pages/Dashboard";
import NotFoundPage from "./pages/404";
import SupportPage from "./pages/Support";
import TermsOfServicePage from "./pages/TermsOfService";
import PricingPage from "./pages/Pricing";
import ProfilePage from "./pages/Profile";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";
// import PaidUsers from "./pages/PaidUsers";
import TradersPage from "./pages/Traders";
import TraderPage from "./pages/Trader";
import FAQPage from "./pages/FAQ";

const App = () => {
  return (
    <Provider>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/forgot-password/:id/:token"
            element={<ResetPasswordPage />}
          />
          {/* <Route path="/paid-users" element={<PaidUsers />} /> */}
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/trader" element={<TradersPage />} />
          <Route path="/trader/:id" element={<TraderPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <div style={{ zIndex: 10 }}>
          <ToastContainer />
        </div>
      </Router>
    </Provider>
  );
};

export default App;
