import React from "react";
import HomeImage from "../assets/images/cryptoillustration.png";
import { Footer, Header } from "../components";
import { useTranslation } from "react-i18next";
import "../styles/global.scss";
import "../styles/home.scss";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="homepage">
        <Header />
        <div className="homepage-container">
          <div className="homepage-description-con">
            <div className="homepage-title">Copy trading</div>
            <div className="homepage-title-2">Synthetic index</div>
            <div className="homepage-description">{t("Home description")}</div>
          </div>
          <img className="homepage-image" src={HomeImage} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
