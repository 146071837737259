import React from "react";
import { Footer, Header } from "../components";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "../styles/global.scss";

const TermsOfServicePage = () => {
  const docs = [
    {
      uri: "https://firebasestorage.googleapis.com/v0/b/ea-traders-club.appspot.com/o/deatc.docx?alt=media&token=befd2fd7-4c78-4bdf-9b0d-4ab429280331&_gl=1*8i37yg*_ga*MTYxNDczMjM2OC4xNjk3NDI5ODU3*_ga_CW55HF8NVT*MTY5OTM2Njk0MC4xMy4xLjE2OTkzNjY5ODAuMjAuMC4w",
      fileType: "docx",
    },
  ];

  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      <Header />
      <div style={{ height: "100vh" }}>
        <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} />
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfServicePage;
