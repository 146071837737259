import React from "react";
import { Button } from "./button";
import "../styles/global.scss";
import "../styles/trader.scss";

export const TraderCard = ({ traderName, traderImg }) => {
  return (
    <div className="tradercard">
      <div className="tradercard-user">
        <div className="tradercard-profile">
          <img
            className="tradercard-profile-img"
            alt="trader-profile"
            src={traderImg}
          />
        </div>
        <div className="tradercard-name">{traderName}</div>
      </div>
      <Button width="100%">Copy trader</Button>
    </div>
  );
};
