import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useAuthContext } from "../providers/provider";
import "../styles/global.scss";
import "../styles/card.scss";

export const Card = ({ children, flip = false, front, back }) => {
  const [isFlip, setIsFlip] = useState(false);
  const { isPhone } = useAuthContext();

  return (
    <div
      onMouseOver={() => !isPhone && setIsFlip(true)}
      onMouseLeave={() => !isPhone && setIsFlip(false)}
      onClick={() => isPhone && setIsFlip(!isFlip)}
      style={{ width: "100%" }}
    >
      {flip ? (
        <ReactCardFlip
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          isFlipped={isFlip}
          flipDirection="horizontal"
        >
          <div className="card">{front}</div>
          <div className="card card-back">{back}</div>
        </ReactCardFlip>
      ) : (
        <div className="card">{children}</div>
      )}
    </div>
  );
};
