import React, { useState } from "react";
import "../styles/global.scss";
import "../styles/faq.scss";
import { CloseIcon } from "../assets/icons";

export const FAQDropDown = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faqdropdown">
      <div className="faqdropdown-question" onClick={() => setIsOpen(!isOpen)}>
        <div>{question}</div>
        <div
          style={{ transform: isOpen ? "rotate(0deg)" : "rotate(-135deg)" }}
          className="faqdropdown-question-icon"
        >
          <CloseIcon color="#ff2e63" />
        </div>
      </div>
      <div
        className={
          isOpen
            ? "faqdropdown-answer faqdropdown-answer-open"
            : "faqdropdown-answer faqdropdown-answer-close"
        }
      >
        {answer}
      </div>
    </div>
  );
};
