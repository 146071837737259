import React from "react";

export const UserCircleIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17.217 19.332A6.982 6.982 0 0012 17c-2.073 0-3.935.9-5.217 2.332M12 21a9 9 0 110-18 9 9 0 010 18zm0-7a3 3 0 110-6 3 3 0 010 6z"
        ></path>
      </g>
    </svg>
  );
};
