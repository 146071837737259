import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/global.scss";
import "../styles/loading.scss";

export const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="loading center">
      <span className="loader"></span>
      <div className="loading-text">{t("Loading please wait")}</div>
    </div>
  );
};
