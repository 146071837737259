import React from "react";
import { Footer, Header, TraderCard } from "../components";
import "../styles/global.scss";
import "../styles/trader.scss";

const TradersPage = () => {
  return (
    <div className="trader container">
      <Header />
      <div className="traders">
        <TraderCard traderName={"Trader"} traderImg={"/logo512.png"} />
        <TraderCard traderName={"Trader"} traderImg={"/logo512.png"} />
        <TraderCard traderName={"Trader"} traderImg={"/logo512.png"} />
        <TraderCard traderName={"Trader"} traderImg={"/logo512.png"} />
        <TraderCard traderName={"Trader"} traderImg={"/logo512.png"} />
      </div>
      <Footer />
    </div>
  );
};

export default TradersPage;
