import React from "react";
import "../styles/global.scss";
import "../styles/button-input.scss";

export const Button = ({
  children,
  onClick,
  width,
  disabled,
  type = "submit",
}) => {
  return (
    <button
      className="button center"
      style={{
        width,
        backgroundColor: disabled && "#181818",
        boxShadow: disabled && "none",
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};
