import React from "react";
import { FAQDropDown, Footer, Header } from "../components";
import "../styles/global.scss";
import "../styles/faq.scss";

const FAQPage = () => {
  return (
    <div className="faq container">
      <Header />
      <div className="faq-container">
        <div className="faq-title-container">
          <div className="faq-title">Frequently asked questions</div>
          <div className="faq-description">
            Got more questions? Feel free to contact us for more information.
          </div>
        </div>
        <div className="faq-container-dropdown">
          <FAQDropDown
            question="Холбогдох"
            answer="Telegram: https://t.me/i_deatc"
          />
          <FAQDropDown
            question="Copy Trade гэж юу вэ?"
            answer="Copy trading нь та хөрөнгийн зах зээлийн талаар огт мэдлэггүй байсан ч туршлагатай арилжаачны дүн шинжилгээгээр хийгдэж буй арилжааг автоматаар хуулбарлаж арилжаанд орохыг хэлнэ. Та өөрийн дансны мэдээллийг бусдад дамжуулах шаардлагагүй бөгөөд өөрийн арилжааны дансны аюулгүй байдлыг бүрэн хамгаалах боломжтой. Тусгайлан хөгжүүлсэн веб-н тусламжтай таны арилжаа ямар ч хоцрогдолгүй туршлагатай Trader-н арилжаа бүрийг дагаж ашиг хүртэх боломжийг олгодог."
          />
          <FAQDropDown
            question="Copy trade хийхэд арилжааны талаар мэдлэг шаардлагатай юу?"
            answer="Энэ нь танаас ямар нэгэн арилжааны шийдвэр гаргалт хийхийг шаардахгүй. Сургалтад сууж цаг заваа алдах шаардлагагүй. Та зөвхөн өөрийн дансаа холболт хийсэн байхад сар бүрийн тогтмол ашиг хүртэх бүрэн боломжтой."
          />
          <FAQDropDown
            question="Арилжаа ямар брокер дээр хийгдэх вэ? Бүртгүүлэх линк?"
            answer="Deriv Брокер дээр арилжаа хийгдэнэ. https://www.deriv.com/ тус линк дээр дарж бүртгэлээ хийлгэх боломжтой."
          />
          <FAQDropDown
            question="Арилжааны дансаа нээж бүртгэлээ баталгаажуулсны дараа хэрхэн дансаа цэнэглэх вэ?"
            answer="Олон төрлийн сонголт байгаа. Хамгийн амархан нь Deriv Брокерт бүртгэлтэй Монгол агентаар дамжуулан цэнэглэлт, үйлчилгээний хөлс төлөх болон татан авалтаа хийх боломжтой. https://t.me/WisdomAlmighty"
          />
          <FAQDropDown
            question="Үйлчилгээний хөлс хэд вэ?"
            answer="Манай үйлчилгээний хөлс 1 сарын 50 USD"
          />
          <FAQDropDown
            question="Дансны хэмжээ хэд байх ёстой вэ?"
            answer="Таны дансны доод үлдэгдэл 100 USD"
          />
          <FAQDropDown
            question="Сарын ашиг хэд байх вэ?"
            answer="Бид өдөрт 10% ашиг буюу сард 300% ашиг болох 300 USD хийх зорилготой."
          />
          <FAQDropDown
            question="Сарын төгсгөлд ашгаа хэрхэн Банкны дансандаа татан авалт хийх вэ?"
            answer="Олон төрлийн татан авалтын сонголт байгаа. Хамгийн хурдан бөгөөд амархан нь Монгол агентаар дамжуулан татан авалт юм."
          />
          <FAQDropDown
            question="Copy Trade-д төлбөрөө төлж холболтоо хийсэн хүн заавал нтернэт орчинд байх шаардлагатай юу?"
            answer="Ямар ч шаардлагагүй. Сарын төлөвлөгөө хүрсэн тохиолдолд татан авалтаа хийх шаардлагатай."
          />
          <FAQDropDown
            question="1 сараас өмнө төлөвлөгөөндөө хүрвэл яах вэ?"
            answer="Тухайн сарын зорилтот төлөвлөгөөнд хүрсэн тохиолдолд Copy Trade зогсож дахин 50USD төлбөрөө төлж дансаа 100USD болгох шаардлагатай."
          />
          <FAQDropDown
            question="Эрсдэл хэр их байх вэ?"
            answer="Мэдээж олон улсын хөрөнгийн зах зээлд арилжаа хийх нь эрсдэлтэй боловч бид эрсдэлийг хамгийн бага түвшинд байлгах өөрсдийн боловсруулсан стратегийн дагуу өдөрт маш бага арилжаа хийдэг. Эрсдэлийг дагагч тал хариуцдаг."
          />
          <FAQDropDown
            question="Арилжаа ямар Platform дээр хийгдэх вэ? Өдөр тутмын үр дүнг хаанаас харах боломжтой вэ?"
            answer="Арилжаа cTrader Platform дээр хийгдэх ба тэндээс өөрийн дансны үлдэгдэл болон мэдээллээ харах боломжтой эсвэл манай https://ea-traders-club.com/ website-с харах боломжтой."
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQPage;
