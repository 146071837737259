import React from "react";
import { DropDownIcon } from "../assets/icons";
import "../styles/dropdown.scss";

export const DropDown = ({ list, value, setValue }) => {
  return (
    <div className="dropdown">
      <select
        className="dropdown-select"
        onChange={(event) => setValue(event.target.value)}
        defaultValue={value}
      >
        {list.map((value) => (
          <option className="dropdown-option" value={value} key={value}>
            {value}
          </option>
        ))}
      </select>
      <div className="dropdown-icon">
        <DropDownIcon />
      </div>
    </div>
  );
};
